import React, { useState } from 'react'
import { Add, Remove } from '@mui/icons-material'
import { Box, Card, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material'
import PrimaryButton from './PrimaryButton'
import CancelIcon from '@mui/icons-material/Cancel';
import axios from '../api/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

const Cart = ({ cartItems, makingTime, handleQuantityChange, qtyChangingIds, isDeleting, deletingIds }) => {
    const navigate = useNavigate();
    const outlet = localStorage.getItem("outlet");
    const [isLoading, setIsLoading] = useState(false)

    // Calculate the total rate
    const totalRate = cartItems.reduce((acc, product) => {
        const baseRate = (product?.rate || 0) * (product?.quantity || 1);
        const addonsRate = product?.addons?.reduce((addonAcc, addon) => {
            return addonAcc + (addon?.offerPrice || 0);
        }, 0) * (product?.quantity || 1);
        return acc + baseRate + addonsRate;
    }, 0)

    const createOrder = async () => {
        setIsLoading(true)
        try {
            const token = localStorage.getItem("authToken");

            const payload = {
                ordersType: "PICKUP",
                paymentMode: "ONLINE",
                outlet: outlet,
                readyTime: makingTime,
            };

            const response = await axios.post("order/create", payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.status === 201) {
                localStorage.setItem("orderId", response?.data?.data?.order?.id);
                localStorage.setItem(
                    "orderAmount",
                    response?.data?.data?.order?.amount
                );
                navigateToOrderPayment();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false)
        }
    };

    const navigateToOrderPayment = () => {
        navigate("/order-payment");
    };

    return (
        <Box
            sx={{
                width: '34%',
                position: 'fixed',
                right: "10px",
                top: 90,
                height: 'calc(100vh - 100px)',
                background: '#dfeef5',
                p: 1,
                overflowY: 'auto'
            }}
        >
            <Box>
                <Typography variant='h6' mb={1} fontWeight={'bold'} textAlign={'left'} color={'#0E4961'}>Your Cart</Typography>
                <Box>
                    <Stack direction={'column'}>
                        {
                            cartItems?.length > 0 && cartItems?.map((product, index) => {
                                const isFirst = index === 0;
                                const isLast = index === cartItems.length - 1;

                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            position: 'relative',
                                            borderTopLeftRadius: isFirst ? '20px' : '0',
                                            borderBottom: (!isFirst && !isLast) || (cartItems?.length === 2) ? '2px dashed #e0e0e0' : 'none',
                                            borderTop: !isFirst && !isLast ? '2px dashed #e0e0e0' : 'none',
                                            borderTopRightRadius: isFirst ? '20px' : '0',
                                            bgcolor: 'white',
                                            minHeight: '70px',
                                            borderBottomLeftRadius: isLast ? '20px' : '0',
                                            borderBottomRightRadius: isLast ? '20px' : '0',
                                            paddingTop: isFirst ? '10px' : '8px'
                                        }}
                                        p={2}
                                    >
                                        {product?.product?.isDelete && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    bgcolor: 'rgba(0, 0, 0, 0.3)',
                                                    zIndex: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 'inherit',
                                                    color: 'red',
                                                    fontWeight: 'bold',
                                                    fontSize: '1.2rem'
                                                }}
                                            >
                                                OUT OF STOCK
                                            </Box>
                                        )}
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <Box sx={{ width: '50%' }}>
                                                <Typography fontWeight={'bold'} textAlign={'left'}>
                                                    {product?.product?.name}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: '25%' }}>
                                                <Stack direction={"row"} alignItems={'center'} justifyContent={'space-around'}>
                                                    <>
                                                        {product?.quantity === 1 ? (
                                                            <DeleteIcon
                                                                onClick={() => {
                                                                    if (!deletingIds.includes(product?._id)) {
                                                                        handleQuantityChange(product?._id, product?.quantity - 1);
                                                                    }
                                                                }}
                                                                className="cursor-p"
                                                                style={{
                                                                    fontSize: "1rem",
                                                                    color: "#fff",
                                                                    backgroundColor: "#fe7678",
                                                                    borderRadius: "50%",
                                                                    padding: "3px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <Remove
                                                                onClick={() => {
                                                                    if (!qtyChangingIds.includes(product?._id)) {
                                                                        handleQuantityChange(product?._id, product?.quantity - 1)
                                                                    }
                                                                }
                                                                }
                                                                className="cursor-p"
                                                                style={{
                                                                    fontSize: "1rem",
                                                                    color: "#fff",
                                                                    backgroundColor: "#00b0ff",
                                                                    borderRadius: "50%",
                                                                    padding: "3px",
                                                                }}
                                                            />
                                                        )}

                                                        <Stack
                                                            direction={"row"}
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            sx={{
                                                                width: "20px",
                                                                textAlign: "center",
                                                                fontWeight: 'bold',
                                                                marginTop: '-1px'
                                                            }}
                                                        >
                                                            {qtyChangingIds.includes(product?._id) ? (
                                                                <CircularProgress size={16} color='success' />
                                                            ) : deletingIds.includes(product?._id) ? (
                                                                <CircularProgress size={16} color='error' />
                                                            ) : (
                                                                product?.quantity
                                                            )}
                                                        </Stack>
                                                        <Add
                                                            onClick={() => {
                                                                if (!qtyChangingIds.includes(product?._id)) {
                                                                    handleQuantityChange(product?._id, product?.quantity + 1)
                                                                }
                                                            }
                                                            }
                                                            className="cursor-p"
                                                            style={{
                                                                fontSize: "1rem",
                                                                color: "#fff",
                                                                backgroundColor: "#00b0ff",
                                                                borderRadius: "50%",
                                                                padding: "3px",
                                                            }}
                                                        />


                                                    </>
                                                </Stack>
                                            </Box>
                                            <Box sx={{ width: '25%', }}>
                                                <Typography fontWeight={'bold'} textAlign={'right'}>
                                                    ₹{(
                                                        (product?.rate || 0) * (product?.quantity || 1) +
                                                        product?.addons?.reduce(
                                                            (acc, item) => acc + (item?.offerPrice || 0),
                                                            0
                                                        ) * (product?.quantity || 1)
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                        <Grid container>
                                            <Typography
                                                sx={{
                                                    color: "#999",
                                                    fontSize: "0.9rem",
                                                    marginTop: "5px"
                                                }}
                                            >
                                                {product?.addons?.map((addon) => addon?.selectedValue)?.join(', ')}
                                            </Typography>
                                        </Grid>
                                    </Box>
                                );
                            })
                        }
                    </Stack>
                    <PrimaryButton
                        title={`Proceed | ₹ ${totalRate?.toFixed(2)}`}
                        onClick={navigateToOrderPayment}
                        disabled={isLoading}
                        spinner={isLoading}
                        disabledTitle={"Please Wait..."}
                        style={{
                            borderRadius: "30px", fontSize: "18px",
                            width: '100%',
                            marginTop: '10px',
                            marginBottom: '20px',
                            background: '#00b0ff',
                            fontWeight: 'bold'
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default Cart
